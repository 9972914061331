import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { StyledLink, Seo } from "../components";
import ErrImage from "../images/ErrImg.jpg";
import { Button, Modal, ModalBody } from "reactstrap";
import Song from "../music/getPaintballOn.mp3";

// styles
const pageStyles = {
	background: `url(${ErrImage}) no-repeat center center`,
	backgroundSize: "cover",
	padding: "96px",
	height: "100vh",
	margin: "auto",
	textAlign: "center",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	color: "#fff",
};

const box = {
	backgroundColor: "rgba(0,0,0,0.6)",
	borderRadius: "5px",
	padding: "20px",
	fontSize: "32px",
};

const credit = {
	position: "absolute",
	bottom: 0,
	fontSize: "12px",
};

const buttonPB = {
	opacity: "0%",
	color: "pink",
	width: "200px",
	height: "60px",
};

// markup
const NotFoundPage = () => {
	const [modalState, setModalState] = useState(false);
	const toggleChristian = () => {
		setModalState(!modalState);
	};

	return (
		<>
			<Seo title='404' description='error not found' />
			<main style={pageStyles}>
				<div>
					<Button style={buttonPB} onClick={toggleChristian}>
						Christian's Easter Egg
					</Button>
				</div>
				<section style={box}>
					<h2>404: Page Not Found</h2>
					<StyledLink to='/'>
						<StaticImage src='../images/NHLogo.png/' alt='Nitehawk Sports' />
					</StyledLink>
				</section>
				<small style={credit}>
					Photo by{" "}
					<a href='https://unsplash.com/@santoelia?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
						HIZIR KAYA
					</a>{" "}
					on{" "}
					<a href='https://unsplash.com/s/photos/paintball?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
						Unsplash
					</a>
				</small>
			</main>
			<div>
				<Modal isOpen={modalState} toggle={toggleChristian} centered size='xl'>
					<ModalBody>
						<div className='m-3 mx-auto text-center'>
							<StaticImage
								src='../images/MrPaintball.jpg'
								alt='Mister Paintball'
							/>
							<audio autoPlay>
								<source src={Song} type='audio/mpeg' />
								<track
									src=''
									kind='captions'
									srcLang='en'
									label='english_captions'
								/>
								Your browser does not support the audio tag.
							</audio>
						</div>
					</ModalBody>
				</Modal>
			</div>
		</>
	);
};

export default NotFoundPage;
